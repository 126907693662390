import React, { useEffect, useReducer } from 'react';

import { API } from 'aws-amplify';

import { Redirect } from 'react-router-dom';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import Button from '../components/button';
import InputField from '../components/input-field';

export default () => {
  const [state, dispatch] = useReducer((state, { event, ...rest }) => {
    if (event === 'fieldUpdated') {
      const { fields, ...stateRest } = state;
      const { field, value } = rest;

      const newFields = { ...fields };

      newFields[field] = value;

      return { ...stateRest, fields: newFields };
    }

    if (event === 'completed') {
      return { completed: true, teamId: state.fields['team-teamId'] };
    }
  }, { fields: {} });

  const fieldUpdated = (e) => dispatch({
    event: 'fieldUpdated',
    field: e.target.name,
    value: e.target.value,
  });

  if (state.completed) {
    return <Redirect to={`/teams/${state.teamId}`} />;
  }

  return <div className="main-area">
    <h1>Create Team</h1>
    <form onSubmit={async (e) => {
      e.preventDefault();
      
      await API.post('api', 'teams', {
        body: Object.entries(state.fields).reduce((all, [key, value]) => {
          const [root, property] = key.split('-');
  
          all[root][property] = value;
  
          return all;
        }, { team: {}, owner: {} }),
      });

      dispatch({ event: 'completed' });
    }}>
      <InputField type="text" onChange={fieldUpdated} required="true" name="team-name" label="Name" />
      <InputField type="text" onChange={fieldUpdated} required="true" pattern="[0-9a-zA-Z\-]+" name="team-teamId" label="ID" />
      <InputField type="date" onChange={fieldUpdated} required="true" name="team-billingDate" label="Billing Date" />
      <InputField type="text" onChange={fieldUpdated} required="true" pattern="[0-9a-zA-Z\-]+" name="team-planId" label="Billing Plan" />

      <h2>Team Owner</h2>
      <InputField type="text" onChange={fieldUpdated} required="true" pattern="^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" name="owner-email" label="Email" />
      <InputField type="text" onChange={fieldUpdated} required="true" name="owner-firstName" label="First Name" />
      <InputField type="text" onChange={fieldUpdated} required="true" name="owner-lastName" label="Last Name" />

      <Button icon={faCheckSquare} type="submit">Create Team</Button>
    </form>
  </div>;
};