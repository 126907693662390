import React, { useEffect, useReducer } from 'react';

import { API } from 'aws-amplify';

import TeamReportTemplate from '../components/team-report-template';

const TeamInfo = ({ teamInfo }) => <div>
</div>;

const TeamUsage = ({ usage }) => <>
</>;

export default ({ match }) => {
  const { id } = match.params;

  const [state, dispatch] = useReducer((state, { event, ...resp }) => {
    if (event === 'loaded') {
      const { data, ...rest } = state;

      return { ...rest, data: resp.data };
    }

    return state;
  }, { loading: true });

  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);

        dispatch({ event: 'loaded', data: resp });
      };
      getData();
    }, [url]);

    return state.data;
  }

  const teamInfo = useFetch(`teams/${id}`);

  console.log(teamInfo);

  if (!teamInfo) {
    return <></>;
  }

  return <div className="main-area">
    <h1>{teamInfo.team.name}</h1>

    <TeamInfo teamInfo={teamInfo.team} />
    <TeamUsage usage={teamInfo.usage} />
    <TeamReportTemplate teamInfo={teamInfo.team} template={teamInfo.template} />
  </div>;
};