import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing, faUsers, faDollarSign, faFile, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

import './menu-bar.css';

export default () => <div className="menu-bar">
  <ul className="menu-bar__items">
    <li className="menu-bar__items__item">
      <div className="menu-bar__items__item__icon"><FontAwesomeIcon icon={faTachometerAlt} /></div>
      <div className="menu-bar__items__item__text">Overview</div>
    </li>
    <li className="menu-bar__items__item">
      <a href="/teams">
        <div className="menu-bar__items__item__icon"><FontAwesomeIcon icon={faUsers} /></div>
        <div className="menu-bar__items__item__text">Teams</div>
      </a>
    </li>
    <li className="menu-bar__items__item">
      <div className="menu-bar__items__item__icon"><FontAwesomeIcon icon={faDollarSign} /></div>
      <div className="menu-bar__items__item__text">Billing</div>
    </li>
    <li className="menu-bar__items__item">
      <div className="menu-bar__items__item__icon"><FontAwesomeIcon icon={faFile} /></div>
      <div className="menu-bar__items__item__text">Reports</div>
    </li>
    <li className="menu-bar__items__item">
      <div className="menu-bar__items__item__icon"><FontAwesomeIcon icon={faLifeRing} /></div>
      <div className="menu-bar__items__item__text">Support</div>
    </li>
  </ul>
</div>;