import React, { useReducer } from 'react';

import { API } from 'aws-amplify';

import { faCheckSquare, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import Button from '../button';
import InputField from '../input-field';

import './team-report-template.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const withoutVersion = ({ version, ...rest }) => rest;

export default ({ teamInfo, template }) => {
  const [state, dispatch] = useReducer((state, { event, ...rest }) => {
    if (event === 'useCustom') {
      const { useCustom, ...stateRest } = state;

      return { ...stateRest, useCustom: rest.value };
    }

    if (event === 'valueUpdated') {
      const newState = { ...state };
      const { field, value } = rest;

      newState[field] = value;

      return newState;
    }

    if (event === 'error') {
      const { error, ...stateRest } = state;
      return { ...stateRest, error: rest.message };
    }

    return state;
  }, {
    useCustom: teamInfo.template ? true : false,
    reportTemplate: JSON.stringify(withoutVersion(template), null, 2),
    version: teamInfo.template,
  });

  const updateReportTemplate = async () => {
    if (!state.useCustom) {
      await API.post('api', `teams/${teamInfo.teamId}/report-template?useDefault=true`);

      return;
    }

    let parsedTemplate;

    try {
      parsedTemplate = JSON.parse(state.reportTemplate);
    } catch (e) {
      dispatch({ event: 'error', message: `Report template is invalid JSON: ${e.message}` })
      return;
    }
    
    if (JSON.stringify(withoutVersion(template)) === JSON.stringify(parsedTemplate)) {
      dispatch({ event: 'error', message: 'Report template not updated' })
      return;
    }

    if (teamInfo.template === state.version) {
      dispatch({ event: 'error', message: 'You must set a unique version' })
      return;
    }
    
    await API.post('api', `teams/${teamInfo.teamId}/report-template`, {
      body: {
        version: state.version,
        ...parsedTemplate,
      },
    });
  };

  return <div>
    <h2>Report Template</h2>
    
    <div className="report-template__use-custom-section">
      {state.useCustom
        ? <>
            <p>Configured to use custom template, click reset to default below to use default template for all new reports</p>
            <Button onClick={() => dispatch({ event: 'useCustom', value: false })}>Use Default Template</Button>
          </>
        : <>
          <p>Configured to use standard template, enter name and modify template below</p>
          <Button onClick={() => dispatch({ event: 'useCustom', value: true })}>Use Custom Template</Button>
        </>}
    </div>

    {state.useCustom ? <InputField type="text" pattern="[0-9a-zA-Z\-]+" label="Version" value={state.version} onChange={(e) => dispatch({ event: 'valueUpdated', field: 'version', value: e.target.value })} /> : <></>}

    <div className="report-template__container">
      <textarea value={state.reportTemplate} disabled={!state.useCustom} onChange={(e) => dispatch({ event: 'valueUpdated', field: 'reportTemplate', value: e.target.value })} />
    </div>

    {state.error ? <div className="report-template__error">
      <div className="report-template__error__icon"><FontAwesomeIcon icon={faExclamationTriangle} /></div>
      <div className="report-template__error__text">
        {state.error}
      </div>
    </div> : <></>}

    <div className="report-template__buttons">
      <Button icon={faCheckSquare} onClick={updateReportTemplate}>Update</Button>
    </div>
  </div>;
};