import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Home from "./pages/home";
import Team from "./pages/team";
import TeamDetails from "./pages/team-view";
import CreateTeam from "./pages/team-create";

export default ({ userContext }) => {
  console.log(userContext)
  if (!userContext.isSystemAdmin) {
    window.location.href = "http://wwww.measureitnow.com/";
  }

  return <Switch>
    <Route path="/" exact component={Team} />
    <Route path="/teams/create" exact component={CreateTeam} />
    <Route path="/teams/:id" exact component={TeamDetails} />
  </Switch>;
};
