import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const ResponsiveLinkWrapper = ({ showLink, linkTo, children }) => showLink ? <Link to={linkTo}>{children}</Link> : children;

export default ({ items, listMode = '', ulClassName, columns }) => <ul className={ulClassName}>
  {items.map(item => {
    const iconStyle = item.iconColour ? { color: item.iconColour } : {};

    return <li className="responsive-table__row" key={item.key}>
      <ResponsiveLinkWrapper showLink={item.link} linkTo={item.link}>
        {item.icon
          ? <div className="responsive-table__row__icons" style={iconStyle}>
            {item.icon}
          </div>
          : <></>}

        {columns.filter(({ hide }) => listMode || !hide).map((column) => {
          const className = `responsive-table__row__column${column.isHeader ? ' responsive-table__row__header-column' : ''}`;
          const style = !listMode ? { 'minWidth': `${column.width}px` } : {};
          const iconElement = column.icon ? <div className="responsive-table__row__column__icon">{column.icon}</div> : <></>;

          return <div className={className} style={style} key={`${item.key}__${column.header}`}>
            {iconElement}
            <div className="responsive-table__row__column__text">
              {typeof column.value === 'string' ? item[column.value] : column.value(item)}
            </div>
          </div>
        })}
      </ResponsiveLinkWrapper>
    </li>;
  })}
</ul>;
