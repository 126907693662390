import React, { useEffect, useReducer } from 'react';

import { API } from 'aws-amplify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import ResponsesiveList from '../components/responsive-list';
import Button from '../components/button';

const TeamList = ({ teams, dispatch }) => <ResponsesiveList
    items={teams.map(({ teamId, name }) => ({
      key: teamId,
      icon: <FontAwesomeIcon icon={faUsers} />,
      link: `/teams/${teamId}`,
      name,
    }))}
    columns={[
      { header: 'Name', isHeader: true, width: 220, value: 'name' },
    ]}
    itemName="team"
    events={{ refresh: () => dispatch({ event: 'refresh' }) }}
  />;

export default () => {
  const [state, dispatch] = useReducer((state, { event, ...resp }) => {
    if (event === 'refresh') {
      const { increment = 0, ...rest } = state;

      return { ...rest, increment: increment + 1 };
    }

    if (event === 'loaded') {
      const { data, ...rest } = state;

      return { ...rest, data: resp.data };
    }

    return state;
  }, { loading: true });

  function useFetch(url) {
    useEffect(() => {
      const getData = async () => {
        const resp = await API.get('api', url);

        dispatch({ event: 'loaded', data: resp });
      };
      getData();
    }, [url]);

    return state.data;
  }

  const teams = useFetch(`teams?increment=${state.increment || 0}`);

  console.log(teams);

  if (!teams) {
    return <></>;
  }

  return <div className="main-area">
    <Button icon={faPlusSquare} link="/teams/create">Create New Team</Button>
    <TeamList teams={teams.teams} dispatch={dispatch} />
  </div>;
};