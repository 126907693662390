import React from 'react';

import './input-field.css';

export default ({ label, ...props }) => <label className="input-field__label">
  <div className="input-field__label__text">
    {label}:
  </div>
  <input {...props} />
</label>;
