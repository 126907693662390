import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';

import './top-bar.css';

export default () => <div className="top-bar">
  <div className="top-bar__title">
    <h1>Measure It Now :: System Admin</h1>
  </div>
  <div className="top-bar__environment">
    <div className="top-bar__environment__icon">
      <FontAwesomeIcon icon={faGlobeAmericas} />
    </div>
    <div className="top-bar__environment__text">
      {process.env.REACT_APP_DEPLOYMENT_NAME}
    </div>
  </div>
  <div className="top-bar__sign-out">
    <FontAwesomeIcon icon={faSignOutAlt} />
  </div>
</div>;